var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-lg-8"},[_c('h2',[_vm._v("Ticket Messages")]),_c('v-card',{staticClass:"d-flex justify-space-between",attrs:{"flat":""}},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item",on:{"click":_vm.backToList}},[_vm._v("Tickets")]),_c('li',{staticClass:"breadcrumb-item",on:{"click":function($event){return _vm.goTo(_vm.getUserTicket.id)}}},[_vm._v("Selected Tickets")]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Ticket Messages")])])]),_c('v-card-actions',{staticClass:"d-flex justify-end"},[(_vm.getUserTicket.resolve < 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.resolveTicket(_vm.ticketMessages[0].ticket_id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,false,187631444)},[_c('span',[_vm._v("Resolve ticket")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}])},[_c('span',[_vm._v("Pending")])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""},on:{"click":function($event){return _vm.messageReply(_vm.getUserTicket.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-reply ")])]}}])},[_c('span',[_vm._v("Send message")])])],1),_vm._l((_vm.ticketMessages),function(message){return _c('v-card',{key:message.id,staticClass:"mb-3"},[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(message.message)+" ")]),_c('v-list-item-title',[_vm._v(" reply by: "+_vm._s(_vm.getName(message.user))+" ")])],1)],1)}),_c('v-dialog',{attrs:{"max-width":"290","transition":"dialog-bottom-transition","overlay-color":"purple"},model:{value:(_vm.systemError),callback:function ($$v) {_vm.systemError=$$v},expression:"systemError"}},[_c('v-card',[_c('v-card-text',[_vm._v(" System error ")]),_c('v-btn',{on:{"click":_vm.error}},[_vm._v(" close ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }